@import url('https://fonts.googleapis.com/css2?family=Gloock&display=swap');
@font-face {
    font-family: 'Breathe Fire'; /* Name of the font */
    src: url('./fonts/Breathe\ Fire\ IV.otf') format('opentype'); /* Path to the font file */
  }
  @font-face {
    font-family: 'bgothm'; /* Name of the font */
    src: url('./fonts/bgothm.ttf') format('truetype'); /* Path to the font file */
  }

  @font-face {
    font-family: 'coprgtb'; /* Name of the font */
    src: url('./fonts/COPRGTB.TTF') format('truetype'); /* Path to the font file */
  }

  @font-face {
    font-family: 'T1Z'; /* Name of the font */
    src: url('./fonts/T1ZIRBALTRIAL-REGULAR-BF64D4406897D27.OTF') format('opentype'); /* Path to the font file */
  }

  @font-face {
    font-family: 'SWISS'; /* Name of the font */
    src: url('./fonts/SWISSKO.TTF') format('truetype'); /* Path to the font file */
  }



.gloock {
    font-family: 'Gloock', serif;
}

.bgothm {
  font-family: 'bgothm', serif;
}


.breathe-fire {
    font-family: 'Breathe Fire', sans-serif; /* Use the font family name */
  }


  .coprgtb {
    font-family: 'coprgtb', sans-serif; /* Use the font family name */
  }

  .t1z {
    font-family: 'T1Z', sans-serif; /* Use the font family name */
  }

  .swiss {
    font-family: 'SWISS', sans-serif; /* Use the font family name */
  }